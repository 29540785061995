<template>
    <div class="select_map_site">
        <div id="select_map_site" v-bind:style="mapStyle"></div>
    </div>
</template>
<script type="text/javascript">
export default {
    props: {
        city: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            dataList: [],
            total: 0,
            map: null,
            markInterval: null,
            mapStyle: {},
        }
    },
    watch: {
        city: function(val) {
            if (val) {
                this.getList()
            }
        },
    },
    methods: {
        setStyle() {
            this.$nextTick(() => {
                let h = $(this.$parent.$el).width() / 3
                console.log(h)
                let obj = {
                    height: h + 'px',
                }
                this.mapStyle = obj
                if (!this.map) {
                    this.$nextTick(() => {
                        setTimeout(this.loadScript)
                    })
                }
            })
        },
        getList() {
            let option = {
                ...this.form,
                pageIndex: 0,
                pageSize: 0,
                belongCity: this.city,
            }
            let url = '/baseinfosite/list'
            this.$http.post(url, option).then(({ detail }) => {
                this.dataList = detail.list
                this.total = detail.total
                this.setMarker(this.dataList)
            })
        },
        loadScript() {
            var _this = this
            let interval = setInterval(() => {
                console.log('loadScript')
                if (window.BMap) {
                    clearInterval(interval)
                    this.map = new window.BMap.Map('select_map_site')
                    this.map.centerAndZoom(
                        new BMap.Point(118.67983, 24.882193),
                        11
                    )

                    var ipLocal = function() {
                        var myCity = new BMap.LocalCity()
                        myCity.get(result => {
                            console.log(result)
                            var cityName = result.name
                            _this.map.setCenter(cityName)
                            let center = result.center
                            _this.map.centerAndZoom(
                                new BMap.Point(center.lng, center.lat),
                                11
                            )
                        })
                    }

                    ipLocal()
                    // var geolocation = new BMap.Geolocation();
                    // // 开启SDK辅助定位
                    // geolocation.enableSDKLocation();
                    // geolocation.getCurrentPosition(function(r) {
                    // 	if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                    // 		var mk = new BMap.Marker(r.point);
                    // 		_this.map.addOverlay(mk);
                    // 		_this.map.panTo(r.point);
                    // 	} else {
                    // 		ipLocal();
                    // 	}
                    // });
                    this.map.enableScrollWheelZoom(true)
                    this.setMarker(this.dataList)
                }
            }, 1000)
        },
        setMarker(list) {
            list = list ? list : this.dataList
            clearInterval(this.markInterval)
            this.markInterval = setInterval(() => {
                console.log(this.map)
                if (this.map) {
                    clearInterval(this.markInterval)
                    var lng = 0,
                        lat = 0
                    list.forEach(item => {
                        let point = new window.BMap.Point(item.lng, item.lat)
                        lng += Number(item.lng)
                        lat += Number(item.lat)
                        let marker = new BMap.Marker(point)

                        var lable = new window.BMap.Label(item.name, {
                            offset: new window.BMap.Size(0, -20),
                        })
                        lable.setStyle({
                            display: 'none',
                            padding: '4px',
                        })
                        marker.setLabel(lable)
                        // 鼠标经过时
                        marker.addEventListener('mouseover', function() {
                            var label = this.getLabel()
                            label.setStyle({
                                display: 'block',
                            })
                        })
                        // 鼠标离开时
                        marker.addEventListener('mouseout', function() {
                            var label = this.getLabel()
                            label.setStyle({
                                display: 'none',
                            })
                        })

                        marker.addEventListener('click', this.markerClick)
                        this.map.addOverlay(marker)
                    })
                    if (list.length) {
                        this.map.centerAndZoom(
                            new BMap.Point(
                                lng / list.length,
                                lat / list.length
                            ),
                            11
                        )
                    }
                }
            }, 1000)
        },
        markerClick(one) {
            var point = one.currentTarget.point
            this.dataList.forEach(item => {
                if (point.lng == item.lng && point.lat == item.lat) {
                    this.$emit('clickMarker', item)
                }
            })
        },
    },
}
</script>
<style type="text/css" lang="scss" scoped="select_map_site">
#select_map_site {
    height: 400px;
    background: #ccc;
}
</style>
