<template>
    <div class="chop_off">
        <jy-dialog type="form" title="线路下发" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :label-width="formLabelWidth">
                    <el-form-item label="" prop="includingAdTip">
                        <el-row>
                            <el-radio v-model="diaform.includingAdTip" :label="true">含广告提示</el-radio>
                        </el-row>
                        <el-row>
                            <el-radio v-model="diaform.includingAdTip" :label="false">不含广告提示</el-radio>
                        </el-row>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            formLabelWidth: '90px',
            dialogVisible: false,
            diaform: {
                includingAdTip: '',
            },
            goType: '',
            routeId: '',
        }
    },
    watch: {
        dialogVisible: function(val) {
            if (!val) {
                this.$refs['dialogForm'].resetFields()
                this.goType = ''
                this.routeId = ''
            }
        },
    },
    activated() {},
    methods: {
        init(option) {
            if (option) {
                console.log(option)
                this.goType = option.goType
                this.routeId = option.routeId
            }
            this.dialogVisible = true
        },
        cancel() {
            console.log('cancel')
            this.dialogVisible = false
        },
        save() {
            let url = '/routeRelSite/downloadRoute'
            let params = {
                ...this.diaform,
                goType: this.goType,
                routeId: this.routeId,
            }
            this.$http.post(url, params).then(res => {
                this.$emit('updateList')
                this.dialogVisible = false
                this.$message({
                    type: 'success',
                    message: res.msg,
                })
            })
        },
    },
}
</script>
