<template>
    <div class="add_update_role">
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogTabelWidth" append-to-body>
            <div>
                <div class="top_value_box">
                    <el-row>
                        <el-col :span="12">
                            <div class="checkmor">
                                <el-form :model="form" label-width="85px">
                                    <el-form-item label="所选站点">
                                        <el-select v-model="selectedIdsList" class="select_view" multiple placeholder @remove-tag="removeTag">
                                            <el-option disabled v-for="(item , key) in selectedList" :key="item.siteId" :label="item.name" :value="item.siteId" class="select_rote_add_update_li">
                                                <el-popover placement="right">
                                                    <span class="box_span" slot="reference">
                                                        <span v-text="item.name||''"></span>
                                                        <span v-text="item.order||''"></span>
                                                    </span>
                                                    <ul class="pop_select_ul">
                                                        <li v-on:click="selectOrder(one,key)" v-for="one in orderList" v-bind:key="one">
                                                            <span v-text="one"></span>
                                                        </li>
                                                    </ul>
                                                </el-popover>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-row>
                                <el-col :span="18">
                                    <span style="opacity: 0">唂</span>
                                </el-col>
                                <!-- <el-col :span="14">
                                    <el-form :model="form" label-width="80px">
                                        <el-form-item label="城市">
                                            <el-cascader v-model="cityId" :options="addressOtions" :props="addressprops" ref="cascaderAddr" :show-all-levels="false"></el-cascader>
                                        </el-form-item>
                                    </el-form>
                                </el-col> -->
                                <el-col :span="6" class="button_box">
                                    <el-button type="primary" v-on:click="toggleType">{{buttonText}}</el-button>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
                <div v-show="type==0">
                    <jy-query ref="form" :model="form">
                        <!-- <jy-query-item prop="siteId" label="站点编号:" :span="1.3">
                            <el-input placeholder="请输入" v-model="form.siteId"></el-input>
                        </jy-query-item> -->
                        <jy-query-item prop="name" label="站名:" :span="1.3">
                            <el-input placeholder="请输入" v-model="form.name"></el-input>
                        </jy-query-item>
                        <jy-query-item label-width="15px">
                            <i class="jy-sousuo" @click="oncheck"></i>
                        </jy-query-item>
                        <!-- <jy-query-item label="城市" :span="1.3" label-width="60px">
                            <el-cascader v-model="cityId" :options="addressOtions" :props="addressprops" ref="cascaderAddr" :show-all-levels="false"></el-cascader>
                        </jy-query-item> -->
                        <!-- <template slot="rightCol">
                            <el-button type="primary" @click="oncheck">查询</el-button>
                            <el-button type="primary" @click="resetForm('form')">重置</el-button>
                        </template> -->
                    </jy-query>
                    <jy-table ref="multipleTable" :data="dataList" @cell-click="handleCurrentSelectChange" @selection-change="selectchange" v-loading="loading">
                        <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                        <jy-table-column type="selection" width="40"></jy-table-column>
                        <jy-table-column prop="siteId" label="站点ID"></jy-table-column>
                        <jy-table-column prop="name" label="站名"></jy-table-column>
                        <jy-table-column prop="siteCategoryName" label="站点类别"></jy-table-column>
                        <jy-table-column prop="belongCity" label="所在城市"></jy-table-column>
                    </jy-table>
                    <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
                </div>
                <div v-show="type==1">
                    <sele-map ref="selectMap" :city="city" @clickMarker="clickMarker"></sele-map>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelFun">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script type="text/javascript">
import map from './selectMap'
export default {
    data() {
        return {
            type: 0,
            isCheck: false,
            title: '站点选择',
            siteTypeList: [
                {
                    label: '全部',
                    value: -1,
                },
                {
                    label: '首末站',
                    value: 0,
                },
                {
                    label: '中途站',
                    value: 1,
                },
            ],
            form: {
                siteId: '',
                name: '',
                siteType: -1,
            },
            dialogVisible: false,
            formLabelWidth: '100px',
            tableSeleList: [],
            selectedList: [],
            selectedIdsList: [],
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [],
            loading: true,
            city: '',
            cityId: '',
            orderList: [],
            addressprops: { value: 'id', label: 'shortName' },
            addressOtions: [],
        }
    },
    activated() {},
    created() {
        // this.getList()
        this.getAllCityTree()
    },
    components: {
        'sele-map': map,
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('form')
            }
        },
        tableSeleList: function() {
            //将此操作放到最后所行
            setTimeout(() => {
                this.setSelectedList(this.tableSeleList, 0)
            })
        },
        selectedList: function() {
            this.setSelectedIdsList()
            this.setOrderList()
        },
        type: {
            handler: function(val) {
                if (val) {
                    this.$nextTick(() => {
                        console.log(this.$refs.selectMap)
                        this.$refs.selectMap.setStyle()
                    })
                }
            },
            immediate: false,
        },
        cityId(val) {
            console.log(val)
            // if (val) {
            //     var timer = setTimeout(() => {
            //         this.city = this.$refs.cascaderAddr.inputValue
            //         console.log(this.city)
            //         clearTimeout(timer)
            //     }, 100)
            // }
        },
    },
    computed: {
        buttonText: function() {
            let t = this.type == 0 ? '地图选择' : '列表选择'
            return t
        },
    },
    methods: {
        //城市组织树
        getAllCityTree() {
            let url = '/info/region/queryProvincesAndCitiesTree'
            this.$http.post(url).then(({ detail }) => {
                this.addressOtions = detail
                console.log(detail)
            })
        },
        init(selectedIdsList, selectedList, cityId, city) {
            this.type = 1
            this.selectedList = selectedList
            this.selectedIdsList = selectedIdsList
            this.cityId = cityId

            var timer = setTimeout(() => {
                this.city = city
                console.log(this.city)
                clearTimeout(timer)
            }, 100)

            this.dialogVisible = true
            this.getList()
            console.log(this.city)
        },
        setOrderList() {
            let list = this.selectedList
            var l = []
            for (let i = 1; i <= list.length; i++) {
                console.log(i)
                l.push(i)
            }
            this.orderList = l
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        getList() {
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                cityId: this.cityId,
            }
            // let arr = JSON.parse(JSON.stringify(option.cityId))
            // if (arr) {
            //     option.cityId = arr.pop()
            // }
            let url = '/baseinfosite/list'
            this.$http.post(url, option).then(
                ({ detail }) => {
                    this.dataList = detail.list
                    this.total = detail.total
                    this.loading = false
                    this.toggleRow()
                },
                () => {
                    this.loading = false
                }
            )
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        // 勾选数据
        selectchange(selection) {
            //   console.log(selection)
            console.log('selectCharge')
            this.tableSeleList = selection
        },
        selectallchange(selection) {
            //   console.log(selection)
            this.tableSeleList = selection
        },
        // 单选
        handleCurrentSelectChange(val) {
            // this.dialogVisible = false
            // this.$emit('updateSite', val)
            console.log(val)
        },
        cancelFun() {
            this.dialogVisible = false
        },
        save() {
            this.$emit('updateSite', this.selectedIdsList, this.selectedList)
            this.dialogVisible = false
        },
        removeTag(item) {
            this.setSelectedList(this.selectedIdsList, 2)
            this.removeTableSeleItem(item)
        },
        removeTableSeleItem(one) {
            this.tableSeleList.forEach(item => {
                if (item.siteId == one) {
                    this.$refs.multipleTable.toggleRowSelection(item)
                }
            })
        },
        toggleRow() {
            this.$nextTick(() => {
                this.dataList.forEach(one => {
                    this.selectedList.forEach(item => {
                        var inTableSeleList = this.tableSeleList.some(
                            oldOne => {
                                return one.siteId == oldOne.siteId
                            }
                        )
                        console.log(inTableSeleList)
                        if (one.siteId == item.siteId && !inTableSeleList) {
                            one.order = item.order
                            item = one
                            this.$refs.multipleTable.toggleRowSelection(one)
                        }
                    })
                })
            })
        },
        setSelectedList(list, type) {
            var oldList = this.selectedList
            var l = []
            if (type == 0) {
                oldList.forEach(item => {
                    var inAll = false,
                        inSele = false
                    inAll = this.dataList.some(itemAll => {
                        return itemAll.siteId == item.siteId
                    })
                    inSele = list.some(itemSele => {
                        return itemSele.siteId == item.siteId
                    })
                    if (!inAll || inSele) {
                        l.push(item)
                    }
                })
                list.forEach(itemSele => {
                    var inOld = oldList.some(itemOld => {
                        return itemOld.siteId == itemSele.siteId
                    })
                    if (!inOld) {
                        l.push(itemSele)
                    }
                })
            } else if (type == 1) {
                console.log('1')
            } else if (type == 2) {
                oldList.forEach(item => {
                    var inIds = false
                    inIds = list.some(one => {
                        return one == item.siteId
                    })
                    if (inIds) {
                        l.push(item)
                    }
                })
            }
            l.forEach((one, k) => {
                one.order = k + 1
            })
            this.selectedList = l
        },
        setSelectedIdsList() {
            this.selectedIdsList = this.selectedList.map(item => {
                return item.siteId
            })
        },
        toggleType() {
            var type = this.type
            if (type == 0 && !this.cityId) {
                this.$confirm('请先输入路线所在城市', '提示', {
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    type: 'warning',
                })
            } else {
                this.type = type == 0 ? 1 : 0
            }
        },
        clickMarker(one) {
            var oldList = this.selectedList
            var inOld = oldList.some(item => {
                return item.siteId == one.siteId
            })
            if (!inOld) {
                one.order = oldList.length + 1
                oldList.push(one)
            }
        },
        selectOrder(order, key) {
            let oldList = this.selectedList.slice(0)
            let obj = oldList[key]
            oldList.splice(key, 1)
            oldList.splice(order - 1, 0, obj)
            oldList.forEach((one, k) => {
                one.order = k + 1
            })
            this.selectedList = oldList
            $('body').click()
        },
    },
}
</script>
<style type="text/css" lang="scss" scoped="site_select">
.top_value_box {
    padding-bottom: 20px;

    .select_view {
        width: 100%;
    }
}

.button_box {
    button {
        float: right;
    }
}

.el-select-dropdown.is-multiple {
    .el-select-dropdown__item {
        &.is-disabled {
            cursor: pointer;

            &:hover {
                background-color: #f5f7fa;
            }
        }

        &.selected.hover {
            &:hover {
                background-color: #f5f7fa;
            }

            background-color: #ffffff;
        }

        &.select_rote_add_update_li {
            background: #ffffff;

            .box_span {
                display: block;

                span {
                    color: rgb(132, 146, 166);
                    font-weight: bold;

                    &:nth-of-type(2) {
                        float: right;
                    }
                }
            }

            &:after {
                display: none;
            }
        }
    }
}
</style>
<style lang="scss">
.top_value_box {
    .checkmor {
        .el-input__inner {
            min-height: 60px !important;
        }
    }
}
</style>

