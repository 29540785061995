<template>
    <div id="line_site">
        <jy-query ref="form" :model="form">
            <!--<jy-query-item prop="id" label="线路编号:">
                <el-input placeholder="请输入" v-model="form.id"></el-input>
            </jy-query-item>
            <jy-query-item prop="routeName" label="线路名称:">
                <el-input placeholder="请输入" v-model="form.routeName"></el-input>
            </jy-query-item>-->
            <jy-query-item prop="routeName" label="线路组织:">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.routeName"></el-input>
                </div>
            </jy-query-item>
            <!-- <jy-query-item prop="siteName" label="站点名称:">
                <el-input placeholder="请输入" v-model="form.siteName"></el-input>
            </jy-query-item> -->
            <jy-query-item prop="state" label="状态:">
                <el-select v-model="form.state" placeholder="请选择">
                    <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('form')">重置</el-button>
                <!-- <jy-query-item>
                    <el-button type="primary" v-on:click="addUdate()">新增</el-button>
                </jy-query-item>-->
                <el-row>
                    <el-button type="primary" v-on:click="addUdate()">新增</el-button>
                    <el-button type="primary" @click="batchdelete">批量删除</el-button>
                    <el-button type="primary" @click="onexport">导出</el-button>
                    <el-button type="primary" @click="onimport">导入</el-button>
                </el-row>
            </template>
        </jy-query>
        <!-- <div class="tip_result">
            该线路为上下行线路，站点总数
            <span>{{result.total}}</span>个，
            其中上行站点数
            <span>{{result.shangxing}}</span>个，您已添加
            <span>{{result.addshangxing}}</span>个；
            下行站点数
            <span>{{result.xiaxing}}</span>个，您已添加
            <span>{{result.addxiaxing}}</span>个。
        </div>-->
        <jy-table ref="multipleTable" tooltip-effect="dark" @select-all="selectallchange" @select="selectchange" :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column type="selection" width="40"></jy-table-column>
            <jy-table-column prop="routeNo" label="线路编号" min-width="120"></jy-table-column>
            <jy-table-column prop="routeName" label="线路名称" min-width="120"></jy-table-column>
            <jy-table-column prop="routeTypeName" label="线路类型" min-width="120"></jy-table-column>
            <jy-table-column prop="goTypeName" label="运行方向" min-width="120"></jy-table-column>
            <jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
            <!--<jy-table-column prop="goTypeName" label="运行方向" min-width="120"></jy-table-column>-->
            <jy-table-column label="状态" min-width="120">
                <template slot-scope="scope">{{scope.row.state|tissueTypes}}</template>
            </jy-table-column>
            <jy-table-column prop="remark" label="备注" class-name="align_left" min-width="200"></jy-table-column>
            <jy-table-column prop="createTime" label="创建日期" min-width="160"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="350"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <add-update-linesite ref="addUpdateOrg" @updateList="getList"></add-update-linesite>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择线路组织" :defaultProps="props"></institutions-tree>
        <advertise-prompt ref="advertisePrompt" @updateList="getList"></advertise-prompt>
        <line-issue ref="lineIssue" @updateList="getList"></line-issue>

    </div>
</template>
<script>
import addUpdateLinesite from '@/components/pages/admin/resourcesAllocation/lineSite/addUpdateLinesite'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import advertisePrompt from '@/components/pages/admin/resourcesAllocation/lineSite/advertisePrompt'
import lineIssue from '@/components/pages/admin/resourcesAllocation/lineSite/lineIssue'

export default {
    data() {
        return {
            siteLineList: [],
            props: {
                children: 'children',
                label: 'text',
            },
            statusList: [
                {
                    label: '全部',
                    value: -1,
                },
                {
                    label: '启用',
                    value: 1,
                },
                {
                    label: '禁用',
                    value: 0,
                },
            ],
            form: {
                routeId: '',
                routeName: '',
                siteName: '',
                state: -1,
            },
            result: {},
            batchdeleteList: [],
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [],
            operateList: [],
        }
    },
    filters: {
        tissueTypes(val) {
            if (val === '1') {
                return '启用'
            } else if (val === '0') {
                return '禁用'
            } else {
                return val
            }
        },
    },
    watch: {
        'form.routeName': function(val, oldVal) {
            if (val != oldVal) {
                if (this.form.routeName == '') {
                    this.form.routeId = ''
                }
                this.getList()
            }
        },
    },

    components: {
        addUpdateLinesite,
        institutionsTree,
        advertisePrompt,
        lineIssue,
    },

    computed: {},

    activated() {
        this.getSiteCase()
    },

    created() {
        this.getList()
        this.setOperateList()
    },

    methods: {
        setOperateList() {
            let l = [
                {
                    name: '修改',
                    icon: 'el-icon-edit-outline',
                    fun: this.addUdate,
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                },
                {
                    name: '广告提示配置',
                    icon: 'el-icon-view',
                    fun: this.advertisePrompt,
                },
                {
                    name: '线路下发',
                    icon: 'el-icon-view',
                    fun: this.lineIssue,
                },
                {
                    name: '启用',
                    fun: this.handleIsenable,
                    isShow: function(row) {
                        if (row.state == '0') {
                            return true
                        } else {
                            return false
                        }
                    },
                },
                {
                    name: '禁用',
                    fun: this.handleIsenable,
                    isShow: function(row) {
                        if (row.state == '1') {
                            return true
                        } else {
                            return false
                        }
                    },
                },
                // {
                //     name: '查看',
                //     icon: 'el-icon-view',
                //     fun: this.handlecheck
                // }
            ]
            this.operateList = l
        },
        // 站点情况
        getSiteCase() {
            //   let url = '/system/org/queryOrgTree'
            //   this.$http.post(url).then(({ detail }) => {
            //     console.log(detail)
            //     this.result = detail
            //   })
            this.result = {
                total: 30,
                shangxing: 15,
                addshangxing: 10,
                xiaxing: 15,
                addxiaxing: 12,
            }
            if (
                this.result.addshangxing + this.result.addxiaxing >
                this.result.total
            ) {
                this.$alert('已超出所设置的站点数，请确认！', '提示', {
                    type: 'warning',
                })
            }
        },
        // 组织
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        addInstitutions(data) {
            console.log(data)
            this.form.routeId = data.id
            this.form.routeName = data.text
        },
        getList() {
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            console.log(option)
            let url = '/routeRelSite/getList'
            this.$http.post(url, option).then(({ detail }) => {
                // console.log(detail)
                this.dataList = detail.list
                this.total = detail.total
                this.batchdeleteList = []
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        // 勾选数据
        selectchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        addUdate(option) {
            if (option) {
                this.$refs.addUpdateOrg.init(option, 'update')
            } else {
                //新增
                // if (this.form.routeName) {
                this.$refs.addUpdateOrg.init(this.form, 'add')
                // } else {
                //   this.$alert('请先选中线路组织！', '提示', {
                //     type: 'warning'
                //   })
                // }
            }
        },
        handlecheck(option) {
            this.$refs.addUpdateOrg.init(option, 'view')
        },
        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该文件?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let option = [row]
                    this.deleteRecord(option)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        // 批量删除
        batchdelete() {
            if (this.batchdeleteList.length == 0) {
                this.$message({
                    type: 'warning',
                    message: '请先选取数据',
                })
            } else {
                this.$confirm('是否删除所选中的记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        let option = this.batchdeleteList
                        this.deleteRecord(option)
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        })
                    })
            }
        },
        deleteRecord(supplierIdAry) {
            this.$http
                .post('/routeRelSite/delete', supplierIdAry)
                .then(data => {
                    if (data.code == '0') {
                        this.$message.success(data.msg)
                        this.getList()
                    } else {
                        this.$message.error(data.msg)
                    }
                })
        },
        // 广告提示配置
        advertisePrompt(row) {
            this.$refs.advertisePrompt.init(row)
        },
        // 线路下发
        lineIssue(row) {
            this.$refs.lineIssue.init(row)
        },
        // 启用禁用
        handleIsenable(row) {
            console.log(row)
            let url = '/routeRelSite/updateState'
            let option = {
                ...row,
            }
            if (option.state == '0') {
                option.state = '1'
            } else {
                option.state = '0'
            }
            this.$http.post(url, option).then(res => {
                this.$message.success(res.msg)
                this.getList()
            })
        },
        onexport() {
            console.log('onexport')
        },
        onimport() {
            console.log('onimport')
        },
    },
}
</script>
<style lang='scss' scoped>
.tip_result {
    margin-bottom: 15px;
    line-height: 40px;
    border: 1px dashed black;
    height: 40px;
    padding: 0px 10px;
}

.tip_result span {
    color: red;
}
</style>
